import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";

import {
  setOwnerToken,
  setHighlightedToken,
} from "../redux/web3Slice";
import { setOpen, setMessage, setType, setLinkMessage, setLink } from "../redux/snackbarSlice";
import { injected } from "../web3/Connectors";
import "../../styles/App.css";

export default function RabbitButton() {
  const reactWeb3 = useWeb3React();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { ownerToken } = useSelector((state) => state.web3);

  const StyledButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    backgroundColor: "#da1b5d",
    fontSize: "70%",
    paddingTop: "2px",
    paddingBottom: "1px",
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: '2%',
    zIndex: 100,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#146483",
    },
  }));

  const handleClick = () => {
    dispatch(setHighlightedToken(ownerToken));
    navigate(`/gallery`)
  };

  useEffect(() => {
    if (!reactWeb3.active) {
      dispatch(setOwnerToken(false));
      dispatch(setHighlightedToken(false));
    }
  }, [reactWeb3.active, dispatch]);


  return (
    <StyledButton onClick={handleClick}>View your NFT</StyledButton>
  );
}
