export const contractAddress = "0x0fb64499a50986afbcf0275a8691e7f704ebaa84";

export const chainAddress = "https://bsc-dataseed.binance.org/";

export const chainNumber = "56";

export const moralisNode =
  "https://speedy-nodes-nyc.moralis.io/420045413e61bb04c6c3fc8f/bsc/mainnet";

export const firebaseNode = "https://us-central1-tiin-nft.cloudfunctions.net/";

export const firebaseStorageImg = 'https://firebasestorage.googleapis.com/v0/b/tiin-nft.appspot.com/o/velo%2Fimg%2F';

export const ipfsGateway = 'https://dweb.link/ipfs';

export const firebaseConfig = {
  apiKey: "AIzaSyBYDF2fvLRnOlBAKIk2WiAl7L29_v3Rk0w",
  authDomain: "tiin-nft.firebaseapp.com",
  databaseURL: "https://tiin-nft-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tiin-nft",
  storageBucket: "tiin-nft.appspot.com",
  messagingSenderId: "233724280579",
  appId: "1:233724280579:web:7a1a93ead2ad5552feb336"
};