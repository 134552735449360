import { initializeApp } from 'firebase/app';
import { getDatabase, ref, startAt, endAt, orderByChild, query, get, onValue } from "firebase/database";

import { firebaseConfig } from '../../config/config';

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export const getRaritys = async (high) => {
  const collectionQuery = query(
    ref(database, 'veloMetadata'),
    orderByChild('chain_id'),
    endAt(high)
  );
  const collectionSnapshot = await get(collectionQuery);

  let tempArr = [];
  collectionSnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    tempArr.push(doc.val());
  });
  return tempArr;
};

export const getSingleRarity = async (id) => {
    return new Promise((resolve, reject) => {

        const dbRef = ref(database, 'veloMetadata/' + id);
            onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            resolve(data)
        });
    })
};