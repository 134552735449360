import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Chart from "react-apexcharts";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import { Link } from "@mui/material";

import LoadingRabbit from "../../components/utils/LoadingRabbit";
import { contractAddress, ipfsGateway, firebaseStorageImg } from "../../config/config";
import { setHighlightedToken } from "../../components/redux/web3Slice";
import PlaceholderImage from "../../components/elements/PlaceholderImage";
import "../../styles/App.css";

import numeral from "numeral";

export default function NFTModal(props) {
  const [loaded, setLoaded] = useState(false);
  const [rarity, setRarity] = useState();
  const [totalRarity, setTotalRarity] = useState([]);
  const [ownerAddress, setOwnerAddress] = useState("");
  const [ownerInventory, setOwnerInventory] = useState([]);
  const [rarityOptions, setRarityOptions] = useState();
  const dispatch = useDispatch();

  const {
    selfMetadata,
    allMetadata,
    openModal,
    handleCloseModal,
    contract,
    tokenID,
  } = props;

  useEffect(() => {
    if (selfMetadata !== undefined) {
      async function fetchWeb3() {
        try {
          let ownerWallet = await contract.ownerOf(tokenID);
          let ownerTokens = await contract.tokensOfWallet(ownerWallet);
          setOwnerAddress(ownerWallet);
          setOwnerInventory(ownerTokens);
        } catch (error) {
          console.error(error);
        }
      }
      fetchWeb3();
    }
  }, [contract, selfMetadata, tokenID]);

  useEffect(() => {
    if (selfMetadata !== undefined && allMetadata.length > 0) {
      let rarityArray = [];
      let percentArr = [];
      let scoreArr = [];
      let totalRarity;
      for (let i = 0; i < selfMetadata.attributes.length; i++) {
        let rarityObj = {
          trait: selfMetadata.attributes[i].trait_type,
          value: selfMetadata.attributes[i].value,
          count: 0,
          percent: 0,
          score: 0,
          friends: [],
          parsedScore: 0,
        };

        for (let j = 0; j < allMetadata.length; j++) {
          for (let k = 0; k < allMetadata[j].attributes.length; k++) {
            let tempType = allMetadata[j].attributes[k].trait_type;
            let tempValue = allMetadata[j].attributes[k].value;

            if (tempType === rarityObj.trait && tempValue === rarityObj.value) {
              rarityObj.count = rarityObj.count + 1;
              if (selfMetadata.name !== allMetadata[j].name) {
                rarityObj.friends.push({
                  idInArr: j,
                  friend: allMetadata[j],
                });
              }
            }

            if (
              allMetadata.length - 1 === j &&
              allMetadata[j].attributes.length - 1 === k
            ) {
              rarityObj.percent = numeral(
                rarityObj.count / allMetadata.length
              ).format("0.00000");
              rarityObj.score = numeral(1 / rarityObj.percent).format(
                "0.00000"
              );
              percentArr.push(rarityObj.percent);
              scoreArr.push(rarityObj.score);
              rarityArray.push(rarityObj);
            }

            if (
              selfMetadata.attributes.length - 1 === i &&
              allMetadata.length - 1 === j &&
              allMetadata[j].attributes.length - 1 === k
            ) {
              let totalPercent = Number(percentArr[0]);
              let totalScore = Number(scoreArr[0]);
              let highestScore = Number(rarityArray[0].score);

              for (let l = 1; l < scoreArr.length; l++) {
                totalPercent = totalPercent * Number(percentArr[l]);
                totalScore = totalScore + Number(scoreArr[l]);

                if (Number(rarityArray[l].percent) > highestScore) {
                  highestScore = Number(rarityArray[l].score);
                }
              }

              let tempFactor = 100 / (highestScore * 1.2);

              let parsedScore = [];
              let nameArray = [];
              let categoryArray = [];
              let countArray = [];
              for (let l = 0; l < rarityArray.length; l++) {
                rarityArray[l].parsedScore =
                  Number(rarityArray[l].score) * tempFactor;
                parsedScore.push(
                  Number(numeral(rarityArray[l].parsedScore).format("0.00"))
                );
                nameArray.push(rarityArray[l].value);
                categoryArray.push(
                  rarityArray[l].trait + ":\n" + rarityArray[l].value
                );
                countArray.push(rarityArray[l].count);

                percentArr[l] = Number(
                  numeral(percentArr[l] * 100).format("0.0000")
                );
                scoreArr[l] = Number(numeral(scoreArr[l]).format("0.0000"));
              }

              setRarity({
                totalScore: totalScore,
                totalPercent: totalPercent,
                parsedScore: parsedScore,
                percentArr: percentArr,
                scoreArr: scoreArr,
                nameArray: nameArray,
                categoryArray: categoryArray,
                countArray: countArray,
              });
              setTotalRarity(rarityArray);
              setRarityOptions({
                options: {
                  chart: {
                    type: "radialBar",
                  },
                  labels: ["Total Score"],
                  fill: {
                    colors: ["#146483"],
                  },
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          color: "#146483",
                          show: true,
                        },
                        value: {
                          fontSize: "12px",
                          show: true,
                          formatter: function (val) {
                            return val + " ";
                          },
                        },
                      },
                    },
                  },
                },
                series: [numeral(totalScore).format("0.00")],
              });
              setLoaded(true);
            }
          }
        }
      }
    }
  }, [allMetadata, selfMetadata]);

  const handleClick = (tokenId) => {
    dispatch(setHighlightedToken(Number(tokenId)));
  };

  const handleLocalClose = () => {
    setLoaded(false);
    handleCloseModal();
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      color: "rgba(255, 255, 255, 0.9)",
      maxWidth: 330,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid gray",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "flex-start",
      minWidth: 100,
    },
  }));

  return selfMetadata ? (
    openModal ? (
      <Dialog maxWidth={"xl"} open={openModal} onClose={handleLocalClose}>
        <DialogTitle>{selfMetadata.name}</DialogTitle>
        <DialogTitle
          sx={{ fontSize: "80%", color: "#808080", marginTop: "-30px" }}
        >
          {selfMetadata.description}
        </DialogTitle>
        <DialogContent
          sx={{
            root: {
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
        >
          {loaded ? (
            <div className={"NftModal"}>
              <div className={"NftModalOuter"}>
                <div className={"NftModalInner"}>
                  <PlaceholderImage 
                      imageClass={'NftModalImage'} 
                      placeholderClass={'NftModalImageHide'} 
                      mainImage={selfMetadata.image_web.replace(
                      "ipfs:/",
                      ipfsGateway
                    )}
                      placeholderImage={`${selfMetadata.off_chain_thumbnail}&w=248&fit=crop&auto=format`}
                  />
                </div>
                <div className={"NftModalInner"}>
                  {totalRarity.length > 0
                    ? totalRarity.map((data, index) => {
                        return (
                          <HtmlTooltip
                            key={index}
                            title={
                              <React.Fragment>
                                <div className="TooltipHeader">
                                  <div className="TooltipHeaderInner">
                                    <p className="PropertiesTitle">Frequency:</p>
                                    <p className="TooltipHeaderText">
                                      {numeral(data.percent).format("0.00%")}
                                    </p>
                                    <p className="PropertiesSubText">
                                      {data.count +
                                        " of " +
                                        allMetadata.length +
                                        " rabbits have this trait"}
                                    </p>
                                  </div>
                                  <div className="TooltipHeaderInner">
                                    <p className="PropertiesTitle">Score</p>
                                    <p className="TooltipHeaderText">
                                      {numeral(data.score).format("0.00")}
                                    </p>
                                    <p className="PropertiesSubText">
                                      Higher score = Rarer trait
                                    </p>
                                  </div>
                                </div>
                                <p>Rabbits with the same property</p>
                                <div className="TooltipInner">
                                  {data.friends.length > 0 ? (
                                    data.friends.map((friend, i) => {
                                      return (
                                        <img
                                          onClick={() =>
                                            handleClick(friend.idInArr)
                                          }
                                          src={`${friend.friend.off_chain_thumbnail}&w=248&fit=crop&auto=format`}
                                          srcSet={`${friend.friend.off_chain_thumbnail}&w=248&fit=crop&auto=format`}
                                          alt=""
                                          loading="lazy"
                                          className="TooltipImage"
                                          key={i + 5000}
                                        />
                                      );
                                    })
                                  ) : (
                                    <p>No rabbits yet...</p>
                                  )}
                                </div>
                              </React.Fragment>
                            }
                          >
                            <div className="PropertiesDiv">
                              <p className="PropertiesTitle">{data.trait}</p>
                              <p className="PropertiesText">{data.value}</p>
                              <p className="PropertiesSubText">
                                {numeral(data.percent).format("0.0%") +
                                  " have this trait"}
                              </p>
                            </div>
                          </HtmlTooltip>
                        );
                      })
                    : null}

                  <div className={"ScoreCard"}>
                    <Chart
                      options={rarityOptions.options}
                      series={rarityOptions.series}
                      type="radialBar"
                      height={220}
                      width={220}
                    />
                  </div>
                </div>
              </div>
              <div className={"NftModalFooter"}>
                <p className={"NftModalFooterText"}>Owner:</p>
                <p className={"NftModalFooterSubText"}>{ownerAddress}</p>
                <p className={"NftModalFooterText"}>Contract:</p>
                <Link
                  href={'https://bscscan.com/address/' + contractAddress}
                  target="_blank"
                  rel="noopener"
                  className="NftModalFooterTextLink"
                  sx={{color: '#fff', marginLeft: '10px', marginBottom: '13px'}}
                >
                  {contractAddress}
                </Link>
                <p className={"NftModalFooterText"}>Token ID:</p>
                <Link
                  href={'https://bscscan.com/token/' + contractAddress + '?a=' + tokenID}
                  target="_blank"
                  rel="noopener"
                  className="NftModalFooterTextLink"
                  sx={{color: '#fff', marginLeft: '10px', marginBottom: '13px'}}
                >
                  {tokenID}
                </Link>
              </div>
              <Link
                  href={'https://metamask.zendesk.com/hc/en-us/articles/360058238591-NFT-tokens-in-your-MetaMask-wallet'}
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  className="NftModalFooterTextLink"
                  sx={{color: '#146483', marginTop: '15px', marginBottom: '15px', textAlign: 'center', width: '98%'}}
                >
                  How to view your Rabbit in wallet
                </Link>
            </div>
          ) : (
            <LoadingRabbit textLabel={"Going down the rabbit hole..."} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLocalClose}>Close</Button>
        </DialogActions>
      </Dialog>
    ):(
      null
    )
  ) : null;
}
