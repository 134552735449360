import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";

import { setOpen, setMessage, setType, setLinkMessage, setLink } from "../redux/snackbarSlice";
import { injected } from "../web3/Connectors";
import "../../styles/App.css";

export default function ConnectButton() {
  const [open, setOpenState] = React.useState(false);
  const web3States = useSelector((state) => state.web3);
  const { active, activate, deactivate, error } = useWeb3React();
  const dispatch = useDispatch();

  const metamaskSignIn = async () => {
    if (!web3States.web3Wallet) {
      setOpenState(true);
    } else {
      try {
        await activate(injected);
        const isUnsupportedChainIdError =
          error instanceof UnsupportedChainIdError;
        if (isUnsupportedChainIdError) {
          dispatch(
            setMessage(
              "Connected to an unsupported network. Please change network to Binance Smart Chain in your wallet."
            )
          );
          dispatch(setType("warning"));
          dispatch(setLinkMessage("How to add BSC to wallet"));
          dispatch(setLink("https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"));
          dispatch(setOpen(true));
          return;
        } else {
          if (active) {
            dispatch(setMessage("Metamask Connected!"));
            dispatch(setType("success"));
            dispatch(setOpen(true));
          }
        }
      } catch (ex) {
        console.log(ex);
        dispatch(
          setMessage(
            "Something went wrong. Please try again! Makeshure you have a Metamask-compatible browser if the problem continues."
          )
        );
        dispatch(setType("warning"));
        dispatch(setOpen(true));
      }
    }
  };

  async function connect() {
    try {
      await activate(injected);
    } catch (ex) {
      console.log(ex);
      dispatch(
        setMessage(
          "Something went wrong. Please try again! Makeshure you have a Metamask-compatible browser if the problem continues."
        )
      );
      dispatch(setType("warning"));
      dispatch(setOpen(true));
    }
  }

  async function disconnect() {
    try {
      deactivate();
    } catch (ex) {
      console.log(ex);
    }
  }

  const StyledButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    backgroundColor: "#da1b5d",
    fontSize: "70%",
    paddingTop: "2px",
    paddingBottom: "1px",
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: '2%',
    zIndex: 100,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#146483",
    },
  }));

  const handleClose = () => {
    setOpenState(false);
  };

  return (
    <div className="ConnectButtonOuter">
      <StyledButton onClick={metamaskSignIn}>Connect Wallet</StyledButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Missing Metamask-wallet!"}
        </DialogTitle>
        <DialogContent>
          <p>
            You need to have Metamask installed in your browser to be able to
            connect.
          </p>
        </DialogContent>
        <DialogActions>
          <Link
            href="https://metamask.io"
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button sx={{ color: "#146483" }} onClick={handleClose}>
              Visit Metamask
            </Button>
          </Link>
          <Button sx={{ color: "#146483" }} onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
