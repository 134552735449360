import React, {useState} from "react";
import { Fireworks } from 'fireworks-js/dist/react'

import BodyFirst from "./start/BodyFirst";
import BodySecond from "./start/BodySecond";
import VeloFirst from "./start/VeloFirst";
import "../styles/App.css";

const fireworkOptions = {
    speed: 1,
    hue: {
      min: 335,
      max: 342
    },
    delay: {
      min: 50,
      max: 80
    },
    acceleration: 1.04
  }

  const fireworkStyle = {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 1
  }

function Start() {
  const [fireworks, setFireworks] = useState(false);

  const handleFireworks = () => {
    setFireworks(true)
  }
  return (
    <div className="AppBody">
      {fireworks ? (
        <Fireworks options={fireworkOptions} style={fireworkStyle} />
      ):(
        null
      )}
      <VeloFirst handleFireworks={handleFireworks}/>
    </div>
  );
}

export default Start;
