import React from "react";
import { useSelector, useDispatch } from "react-redux";


import CardOne from "../../components/animations/CardOne";
import "../../styles/App.css";

export default function VeloFirst(props) {
  return (
    <div className="VeloBodyFirst">
      <CardOne handleFireworks={props.handleFireworks}/>
    </div>
  );
}
